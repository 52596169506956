import React, {useState, useEffect} from 'react'
import "./style.css"
import slide1img from "../../../assest/images/slide1.png"
import slide2img from "../../../assest/images/slide2.png"
import slide3img from "../../../assest/images/slide3.png"
import bgDecImage from "../../../assest/images/bg-dec-blue.png"

function Index() {

    const items = [
        {img: slide1img, header: "Let’s Help You Run Those Errands" },
        {img: slide2img, header: "Fast and Reliable Delivery"},
        {img: slide3img, header: "Your Parcel At Your Doorstep"},
    ]

    const [slideIndex, setSlide] = useState(0);

    const carouselScroll = () => {
        if(slideIndex === items.length - 1){
            setSlide(0);
        } else {
            setSlide(slideIndex + 1);
        }
    }

    useEffect(()=>{
        const interval = setInterval(()=> carouselScroll(), 4000);
        return () => clearInterval(interval);
    })
    return (
        <div className='hero-container'>
        <div className='container content-wrapper flex'>
            <div className='flex hero-wrap'>
                <div className='order-2'>
                <div className='text-wrap'>
                <img src={bgDecImage} className="deco" alt='pattern'/>
                <div className="fast title">
                   {items[slideIndex].header}
                </div>
                <div className='call-away'>We are just one call away</div>
                </div>
                <div className='padB-1'>
                <a href='https://vendexpress.user.ng'>
                    <button className='started-btn start-btn'>
                        Get Started
                    </button>
                </a>
                </div>
                </div>
                <div className='img-wrap order-1'>
                    <img src={items[slideIndex].img} alt='man with phone' />
                </div>
            </div>
            </div>
        </div>
    )
}

export default Index
