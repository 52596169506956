import React from 'react';
import "./style.css";
import rider from "../../../assest/images/rider1.png"

function Index() {
    return (
        <div className='container rider-container bg-blue '>
        <div className='rider-wrap content-wrapper'>
            <div className='flex'>
              <div>
              <div className='text-wrap'>
                    <h1>Become a Rider</h1>
                    <p>Increase your earning capacity as you partner with us 
                        to deliver happiness to our customers.
                    </p>
                    </div>
                    <div className='btn-wrap'>
                    <a href='https://vendexpressrider.user.ng/rider/register' target="_blank" rel="noreferrer">
                        <button className='started-btn'>Get Started</button>
                    </a>
                    </div>
              </div>
                <div className='img-wrap'>
                    <img src={rider} alt='vendex rider' />
                </div>
            </div>
            </div>
        </div>
    )
}

export default Index
