import React from 'react'
import "./style.css"
import riderImg from "../../../assest/images/riders3.png"

function Index() {
    return (
        <div className='container work-container'>
            <div className='content-wrapper'>
            <div className='work-header'>
            <div className='title'>
            Careers
            </div>
            </div>
        <div className='work-wrap'>
            <div className='work-flex'>
              <div className='text-wrap'>
                    <h1>Work With Us </h1>
                    <p>
                    Join the fastest-growing team of dispatch riders in Benin-City as we deliver happiness, one package at a time. 
                    </p>
                    <div className='work-btn'>
                    <a href='https://vendexpressrider.user.ng/rider/register'  target="_blank" rel="noreferrer">
                        <button className='primary-btn'>
                        Become a Dispatch Rider
                        </button>
                    </a>
                    </div>
                </div>
                <div className='img-wrap'>
                    <img src={riderImg} alt='vendex riders' />
                </div>
              </div>
            </div>
            </div>
            </div>
    )
}

export default Index
