import React from 'react';
import "./style.css";
import parcelImg from "../../../assest/images/vendex-package.png";
import partnershipsImg from "../../../assest/images/hand-shake.png";
import foodImg from "../../../assest/images/burger.png";
import ridersImg from "../../../assest/images/riders2.png";
import businessImg from "../../../assest/images/mandelivery.png";
import waybillImg from "../../../assest/images/vendex-bus.png";




function Index() {
    const items = [
        {
            img: parcelImg,
            label: "Instant Parcel Delivery",
            subTitle: "Your Parcel, Our Responsibility.",
            description: "Book a rider now and get your parcels delivered to you right away "
        },
        {
            img: foodImg,
            label: "Food Delivery",
            subTitle: "No more long trips and queues to get your favourite meals.",
            description: "Get meals, pizza, drinks, snacks and all kinds of food delivered from your favorite restaurants, with no stress."
        },
        {
            img: ridersImg,
            label: "Next Day Delivery",
            subTitle: "",
            description: "No more rush. Book your deliveries ahead of time and skip the waiting line. "
        },
        {
            img: businessImg,
            label: "Business Orders",
            subTitle: "",
            description: "Buy and sell from your favorite businesses and let us deliver your orders right to your Doorstep."
        },
        {
            img: partnershipsImg,
            label: "Partnerships",
            subTitle: "",
            description: "Are you a business owner? Partner with us in delivering your products quickly and efficiently to your customers all over Benin-City."
        },
        {
            img: waybillImg,
            label: "Waybill or Bus Park Delivery",
            subTitle: "",
            description: "Got packages to send out of Benin-City or packages incoming to Benin-City? Save time, effort and money and let us run that errand for you."
        }
    ]
    return (
      <div className='container services content-wrapper'>
          <div className='services-header'>
            <div className='title'>
                Our Services
            </div>
            <div>
                <p>
                At Vend Express Logistics, our goal is to make the world a better place to live in, one delivery at a time. Let us deliver packages and happiness right to your doorstep.
                 </p>
            </div>
        </div>
        <div className='services-list'>
        <div className='services-flex'>
                { items.map((item, i) => {
                    return (
                        <div className='card service-card' key={i}>
                            <div className='card-img img-wrap'>
                                <img src={item.img} alt={item.label} />
                            </div>
                            <div className='card-desc flex'>
                                <div className='card-wrap'>
                                   <div className='desc-text'>
                                   <p className='label'>{item.label}</p>
                                    <p className='sub-title'>{item.subTitle}</p>
                                    <p className='desc'>{item.description}</p>
                                   </div>
                                    <div className='book-btn'>
                                    <a href='https://vendexpress.user.ng'>
                                    <button className='primary-btn'>Book Now</button>
                                    </a>
                                    </div>
                                </div>
                            </div>
                            
                         </div>
                    )
                })}
                </div>
        </div>
      </div>
    )
}

export default Index
